


.badge{
    line-height: 1.5;
    border-radius: 0.5rem;
    // font-size: 12px;
    // font-weight: normal;
    padding: 4px 10px;
	border:1px solid transparent;
	min-width: 28px;
    min-height: 28px;
}

.badge-rounded{
    border-radius: 20px;
    padding:3px 13px ;
}
.badge-circle{
    border-radius: 100px;
    padding: 4px 7px;
}





.badge-outline-primary{
    border: 1px solid var(--primary);
    color: var(--primary);
}
.badge-outline-secondary{
    border: 1px solid $secondary;
    color: $secondary;
	@at-root [data-theme-version="dark"] & {
		color:$body-color;
	}
}
.badge-outline-success{
    border: 1px solid $success;
    color: $success;
}
.badge-outline-info{
    border: 1px solid $info;
    color: $info;
}
.badge-outline-warning{
    border: 1px solid $warning;
    color: $warning;
}
.badge-outline-danger{
    border: 1px solid $danger;
    color: $danger;
}
.badge-outline-light{
    border: 1px solid $border;
    color: $dark;
	@at-root [data-theme-version="dark"] & {
		color:$body-color;
	}
}
.badge-outline-dark{
    border: 1px solid $dark;
    color: $dark;
	@at-root [data-theme-version="dark"] & {
		color:$body-color;
	}
}



.badge-xs {
    font-size: 10px;
    padding: 0px 5px;
    line-height: 18px;
	min-width: unset;
    min-height: unset;
}
.badge-sm {
	font-size: 11px;
    padding: 5px 8px;
    line-height: 11px;
	min-width: unset;
    min-height: unset;
}
//.badge-md {
//    font-size: 13px;
//    padding: 0px 9px;
//    line-height: 25px;
//}
.badge-lg {
    font-size: 14px;
    padding: 0px 10px;
    line-height: 30px;
}


.badge-xl {
    font-size: 15px;
    padding: 0px 15px;
    line-height: 35px;
}
.badge-default{
    background: #ADB6C7;
}
.badge-success {
    background-color: $success;
}
.badge-info {
    background-color: $info;
}
.badge-primary {
    background-color: var(--primary);
}
.badge-secondary {
    background-color: $secondary;
}
.badge-warning {
    background-color: $warning;
	color:$charade;
}
.badge-danger {
    background-color: $danger;
}
.badge-dark {
    background-color: $dark;
}
.badge-light {
    background-color: $light;
}




.light.badge-default{
    background: #ADB6C7;
}
.light.badge-success {
    background-color: $success-light;
	color:$success;
	@at-root [data-theme-version="dark"] & {
		background-color: lighten($success,35%);
	}
}
.light.badge-info {
    background-color: lighten($info,35%);
	color:$info;
	@at-root [data-theme-version="dark"] & {
		background-color:lighten($info,35%);
	}
}
.light.badge-primary {
    background-color: lighten($primary,55%);
	color:var(--primary);
	@at-root [data-theme-version="dark"] & {
		background-color: lighten($primary,35%);
	}
}
.light.badge-secondary {
    background-color: $secondary-light;
	color:$secondary;
	@at-root [data-theme-version="dark"] & {
		background-color:lighten($secondary,35%);
	}
}
.light.badge-warning {
    background-color: lighten($warning,30%);
	color:$charade;
	@at-root [data-theme-version="dark"] & {
		background-color: lighten($warning,30%);
		
	}
}
.light.badge-danger {
    background-color: $danger-light;
	color:$danger;
	@at-root [data-theme-version="dark"] & {
		background-color: lighten($danger,45%);
	}
}
.light.badge-dark {
    background-color: $dark-light;
	color:$dark;
	@at-root [data-theme-version="dark"] & {
		background-color:lighten($dark-light,45%);
		color:$body-color;
	}
}





//

.bootstrap-label{
    .label{
        display: inline-block;
        margin-right: 1rem;

        &:last-child{
            margin-right: 0;
        }
    }
}

.badge-demo{

    .badge{
        margin-right: 5px;
        margin-bottom: 5px;

        &:last-child{
            margin-right: 0;
        }
    }
}

.bootstrap-badge-buttons{

    button{
        margin-right: .2rem;
        margin-bottom: 1rem;

        &:last-child{
            margin-right: 0;
        }
    }
}