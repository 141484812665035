.chat-left-area{
	.chat-sidebar{
		height: calc(90vh - 82px);
		// height: calc(100vh - 11.25rem);
		@include respond ('tab-land'){
			height: calc(100vh - 5.9rem);
		}
		@include respond ('phone-land'){
			box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.05);
		}
		
		border-top: none;
		overflow-y:scroll;
	}
}
.message-body{
	min-height:auto!important;
}

.message-box{
	

    padding-top: 1rem;
	padding-bottom: 1rem;
	
	.input-group.search-area{
		width: 75%;
		.input-group-text{
			padding: 0.32rem 0.75rem;
		}
	}
	img{
		height:5rem;
		width:5rem;
		border-radius:$radius;
	}
	h4{
		font-weight:700;
	}
	span{
		font-size:1.125rem;
	}
	.chat-hamburger {
		display: flex;
		align-items: center;
		.videos-btn{
			background-color: $secondary;
			margin-right: 0.625rem;
			width: 2.5rem;
			height: 2.5rem;
			border-radius: $radius;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&.style-1{
		padding:1rem;
		border:0;
		height: 5rem;
		border-radius: $radius;
		@include respond('phone'){
			height: auto;
		}
		h4{
			margin-bottom:.25rem;
			@include respond ('phone'){
				margin-bottom:0;
				font-size: 0.938rem;
			}
		}
		span{
			font-size:14px;
		}
		img{
			height:3rem;
			width:3rem;
			@include respond ('phone'){
				height: 3rem;
				width: 3rem;
			}
		}
	}
	@include respond ('phone'){
		img{
			height: 3rem;
			width: 3rem;
		}
		h3{
			font-size: 1rem;
		}
		span{
			font-size: .85rem;
		}
	}
	.add{
		width:3rem;
		height: 3rem;
		border-radius: $radius;
		padding: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		@include respond('laptop'){
			width:2.5rem;
			height: 2.5rem;	
		}
	}
}
.chat-people{
	padding:1.25rem 0;
}
.chat-tabs{
	& > h4{
		font-size: 1.125rem;
		padding-top: 1.125rem ;
		margin: 0;
		
	}
	ul{
		padding: 1rem 0;
	}
	.chat-bx.chats{
		position: relative;
		.chat-img{
			position: relative;
		
			&::before{
				content: '';
				display: block;
				background-color: #1EBA62;
				border: 2px solid $white;
				position: absolute;
				bottom: -2px; 
				right: -2px;
				width: 1.125rem;
				height: 1.125rem;
				border-radius: 50%;
			}
			
		}
	}
	.chat-bx{
		padding: 0.625rem 0;
		position:relative;
		z-index:1;
		display:flex;
		cursor:pointer;
		align-items:center;
		margin-bottom: 0.5rem;
		.right-info{
			width: 4.375rem;
			text-align: right;
			p{
				font-size:.8rem;
				margin-top: 0;
				margin-bottom: .5rem;	
			}
			@include respond ('laptop'){
				width: 4rem;
			}
		}
		.mid-info{
			width: calc(100% - 9rem);
			.name{
				font-size: 0.938rem;
				margin-bottom: .25rem;
			}
			span{
				white-space:nowrap;
				text-overflow:ellipsis;
				display:block;
				overflow:hidden;
			}
			@include respond ('laptop'){
				width: calc(100% - 8rem);
			}
		}
		
		.chat-img{
			position: relative;
			margin-right: 1rem;
			img{
				height: 3rem;
				width: 3rem;
				border-radius:$radius;
				margin-right: 0rem;
				object-fit: cover;
				@include respond ('laptop'){
					height: 3rem;
					width: 3rem;
				}
			}
			.active{
				height:1rem;
				width:1rem;
				border:0.125rem solid $white;
				border-radius:0.25rem;
				background:$secondary;
				position: absolute;
				bottom: -0.0625rem;
				right: 1rem;
			}
		
		}
		.badge {
			border-radius:0.375rem;
		}
		&:last-child{
			border-bottom:0;
		}
		@include respond ('laptop'){
			padding: .75rem 0;
		}
		&:after{
			content:none;
			position:absolute;
			top:0;
			left:0;
			height: calc(100% + 4px);
			width: calc(100% + 30px);
			z-index:-1;
			background-color:#f0f0f0;
			border-radius:$radius;
			margin: -2px -15px;
			 @at-root [data-theme-version="dark"] & {
				background-color:$d-bg;
			 }
		}
		&:hover,
		&.active{
			&::before{
				content: '';
				display: none;
			}
			&:after{
				content:"";
			}
			.mid-info{
				h4{
					color:var(--primary);
				}
			}
		}
	}
}
.chat-box-area{
	height: calc(90vh - 17.125rem);
    overflow-y: scroll;
	.media{
		.message-received {
			width: 62%;
			text-align: left;
		
				p{
					background:$white;
					text-align: left;
					border-radius:$radius;
					border-top-left-radius: 0;
					padding: 0.625rem 1.25rem;
					display: table;
					color: #000;
					 @at-root [data-theme-version="dark"] & {
						background-color:$dark-card;
						color:$white;
					}
				}
				span{
					color: var(--primary);
				}
				
					.dz-media{
						width: 22.75rem;
						height: 15.375rem;
						padding: 0.625rem;
						background-color: $white;
						@include respond('phone-land'){
							width: 14.75rem;
							height: 12.75rem;
						}
					}
				
			
			@include respond('phone'){
				width:100%;	
			}
		}
	}
	.message-sent{
		width: 62%;
		text-align: right;
		max-width: 83%;
		@include respond ('tab-port'){
			width: 84%;
		}
		span{
			display:block;
		}
	}
	.message-sent p{
		background: var(--primary);
		text-align: left;
		border-radius:$radius;
		border-top-right-radius: 0;
		padding: 0.625rem 1.25rem;
		color: #fff;
		display:inline-block;
	}
	&.style-1{
		height: 21.5rem;
		.message-received {
			p{
				background:#eeee;
				font-size:0.875rem;
			}
		}
		.message-sent{
			p{
				background:var(--primary);
				font-size:0.875rem;
				color:$white;
			}
		}
	}
	@include respond ('tab-land'){
		height: calc(100vh - 16.8rem);
	}
	@include respond ('phone'){
		height: calc(100vh - 12.8rem);
	}
}
.type-massage{
	background: $white;
	padding: 0.625rem;
	border-radius: $radius;
	border: 1px solid $border;
	@at-root [data-theme-version="dark"] & {
		background-color:$dark-card;
		border-color:$dark-card;
	
	}
	.input-group{
		textarea{
			min-width:7.375rem;
			border: 0;
			height: 3.4375rem !important;
			resize: none;
			padding: 1rem 0.9375rem;
			border-bottom: none;
			background: transparent;
			border-top-left-radius: 0.625rem !important; 
			border-bottom-left-radius: 0.625rem !important;
			overflow: hidden;
			white-space: nowrap;
			&:hover,
			&:focus{
				background: transparent;
			}
			@include respond ('phone'){
				height: 2.5rem !important;
				line-height: 1.5;
				padding: 0.4375rem 0.5rem;
			}
		}
		button{
			span{
				@include respond ('phone'){
					display:none;
				}
			}
		}
	}
	.input-group{
		align-items:center;
	}
	&.style-1{
		border: 0;
		margin:0;
		padding: 0.375rem;
		background:rgba(219, 219, 219, 0.25);
		textarea{
			border: 0;
			height: 2.5rem !important;
			resize: none;
			padding: 0.0625rem 0.9375rem;
			background: transparent;
		}
	}
	.input-group-append{
			display: flex;
			align-items: center;		
		.btn{
			text-align:center;
			margin: 0 0.5rem;
			@include respond ('phone'){
				height:2.5rem;
				padding: 0.5rem 0.625rem;
				margin:0 0.3rem;
				svg{
					width: 12px;
					height: 12px;
				}
			}
		}
	}
	.share-btn{
		height:2.5rem;
		width:2.5rem;
		padding: 0;
		background: var(--rgba-primary-1);
		margin-right: 1.0625rem;
		border-radius: $radius;
		svg{
			path{
				fill:var(--primary);
			}
		}
		
	}
	
}

.chat-icon{
	border-bottom:0.0625rem solid #F0F0F0;
	padding-bottom:1.5625rem;
	ul{
		display: flex;
		justify-content: space-around;
		li{
			a{
				height:3rem;
				width:3rem;
				background:#F0F0F0;
				line-height:3rem;
				display:block;
				border-radius:$radius;
				text-align:center;
				&:hover{
					background:var(--primary);
					svg{
						path{
							fill:$white;
						}
					}
				}
			}
		}
	}
}

.share-files{
	border-bottom:0.0625rem solid #F0F0F0;
	padding-bottom: 1.125rem;
	&:last-child{
		border-bottom:0;
	}
	ul{
		li{
			display:flex;
			justify-content:space-between;
			align-items:center;
			padding: 0.5625rem 0;
			cursor: pointer;
			&:first-child{
				padding-top:0;
			}
			h4{
				white-space:nowrap;
			}
			& > div{
				flex:1;
			}
		}
	}
}
.file{
	height:2.5rem;
	width:2.5rem;
	min-width:2.5rem;
	line-height:2.4rem;
	text-align:center;
	background:#F0F0F0;
	display:block;
	border-radius:$radius;
	svg{
		height:1.5rem;
		width:1.5rem;
	}
	
	
}
.share-widget{
	height: calc(100vh - 11.25rem);
    overflow: hidden;
	@include respond ('laptop'){
		box-shadow: 0 0 30px 5px rgba(0,0,0,.05);
	}
	@include respond ('tab-land'){
		height: calc(100vh - 9.25rem);
	}
}

.file-list{
	li{
		& > div{
			width:calc(100% - 3.5rem);
			& > div{
				width:calc(100% - 3rem);
			}
		}
		h4{
			width: 3.5rem;
			text-align:right;
		}
		&:hover{
			.file{
				background:var(--primary);
				svg{
					path{
						fill:$white;
					}
				}
			}
		}
	}
	.fl-info{
		flex:1;
		span{
			font-size:0.8rem;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			display: block;
		}
	}
}
.contact-list{
	li{
		width:17%;
		padding: 0 0.2rem;
		img{
			width:100%;
			border-radius:$radius;
			@include transitionMedium;
		}
		@include respond ('laptop'){
			padding:0 .15rem;
		}
		&:hover{

				img{
					filter: blur(1px);
				}
		}
	}
	
}
.chat-left-area{
	@include respond ('tab-land'){
		width: 22rem;
		position: absolute;
		left: -22rem;
		z-index: 2;
		@include transitionFast;
		&.active{
			left: 4.5rem;
		}
	}
	@include respond ('phone-land'){
		&.active{
			left: 0rem;
		}
	}
}
.chat-right-area{
	.chat-rightarea-btn{
		display:none;
	}
	@include respond ('laptop'){
		width: 23rem;
		position: absolute;
		right: -16.5rem;
		@include transitionFast;
		&.active{
			right: 4.2rem;
		}
		.chat-rightarea-btn{
			position: absolute;
			top: 7rem;
			left: -1.1rem;
			width: 2.2rem;
			height: 3rem;
			background: var(--primary);
			display: inline-flex;
			color: #fff;
			font-size: 1.2rem;
			align-items: center;
			justify-content: center;
			border-radius: .5rem 0 0 .5rem;
			@include transitionFast;
			&.active{
				i{
					transform: rotateY(180deg);
				}
			}
		}
	}
	@include respond ('phone-land'){
		right: -22rem;
		&.active{
			right: -1.5rem;
		}
	}
}
.chat-mid-area{
	@include respond ('laptop'){
		flex: 1;
		overflow: hidden;
	}
	@include respond ('phone-land'){
		.chat-hamburger {
			display: flex;
			align-items: center;
			cursor:pointer;
			justify-content: center;	
			line-height: 45px;
			text-align: center;
			//background-color: #6418C3;
			color: #fff;
			border-radius: 50%;
			position:relative;
			margin-top:0.625rem;
			//z-index: 5;
			span {
				display: block;
				height: 2px;
				margin: 2px auto;
				width: 20px;
				border-radius: 3px;
				background-color: #000;
			}
		}
	}
	@include respond ('phone'){
		margin-top:0.625rem;
	}
}

// ---chat-content--
.chart-content {
    padding: 1rem;
	@include respond('tab-port'){
		padding-bottom: 0;
	}
	
	.media {
		.dz-media{
			width: 3rem;
			height: 3rem;
			border-radius: $radius;
			overflow: hidden;
			margin-right: 0.625rem;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
}


.chat-toggle{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    height:	2.5rem;
    width: 2.5rem;
    line-height: 2.2rem;
    text-align: center;
    color: #fff;
    border-radius: $radius;
    position: relative;
    display: none;
	margin-right: 0.625rem;
	background-color: var(--rgba-primary-1);
    @include respond('tab-land'){
        display: flex;
    }
	svg{
		width: 25px;
		height: 25px;
		g{
			
			path{
				fill: var(--primary);
			}
		}
	}
	&::before{
		content: '';
		display: block;
		position: fixed;
		background: rgba(0, 0 ,0,0.5);
		top: 0;
		left: 0;
		width: 0vw;
		height: 0vh;
	}
    span{
        display: block;
        height: 2px;
        margin: 2px auto;
        width: 20px;
        border-radius: 3px;
        background-color: #000;
    }
}
.chat-toggle.active{
	&::before{
		z-index: 1;
		width: 100vw;
		height: 100vh;
	}
}
.chat-sidebar::-webkit-scrollbar {
    width: 5px;
}

.chat-sidebar::-webkit-scrollbar-track{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 0px;
}

.chat-sidebar::-webkit-scrollbar-thumb{
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
	
}
.chat-box-area::-webkit-scrollbar {
    width: 5px;
}

.chat-box-area::-webkit-scrollbar-track{
	// -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 0px;
}

.chat-box-area::-webkit-scrollbar-thumb{
    border-radius: 0px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
		
}
.custom-tab-list{
	.search-drop{
		.dropdown-toggle{
			border-color: #d7dae3;
			background-color: transparent  ;
			color: #727272;
			padding: 0.588rem 1.2rem;
		}
		.dropdown-menu {
			min-width: 5rem;
			.dropdown-item {
				font-size: 0.875rem;
				&:hover{
					background-color: $dark-card;
				}
			}
		}
	}
}