//style for all basic tables
.table {
	// border-collapse: inherit;
	border-collapse: collapse;
	color:
	strong{
		color:$dark;
	}
	thead{
		tr{
			background: var(--primary);
			color: white;
			box-shadow: 0px 10px 50px 0px rgba(219, 208, 208, 1);
			th{
				color:$white !important;
			}
		}
	}
	th{
		color: #fff;
		padding: 25px 30px;
		border-bottom:1px solid rgba($border,0.3);
		border-top:none;
		@include respond('phone-land') {
			padding: 10px 15px;
		}
		@at-root [data-theme-version="dark"] & {
            border-color: $d-border;
        }
	}
	td{
		padding: 25px 30px;
        border-color: rgba($border,0.5);
		border-bottom:1px solid;
		border-top:none;
		@include respond('phone-land') {
			padding: 10px 15px;
		}
        @at-root [data-theme-version="dark"] & {
            border-color: $d-border;
        }
	}
	&.table-striped tbody tr:nth-of-type(odd),
	&.table-hover tr:hover{
	    background-color:$l-bg;
		
		@at-root [data-theme-version="dark"] & {
            background-color: $d-bg;
        }
	}
	thead{
		background: $white;
		box-shadow: none;
	}
    thead th {
        border-bottom-width: 0px;
        text-transform: capitalize;
        // color: $dark ;
        font-size: 18px;
		white-space:nowrap;
        font-weight: 500;
        letter-spacing: 0.5px;
        border-color: rgba($border,0.3);
		@at-root [data-theme-version="dark"] & {
            border-color: $d-border;
        }
    }
    tbody  tr td {
        // color: $l-ctd;
        // line-height: 2.5rem;
        vertical-align: middle;
		color:#383434;
        border-color: rgba($border,0.3);
        @at-root [data-theme-version="dark"] & {
            border-color: $d-border;
        }
    }
	
	//delete header borders without '.borders tables'
    &:not(.table-bordered) {
        thead th {
            border-top: none;
        }
    }

    //heading background
    .thead-primary th {
        background-color: $primary;
        color: $white;
    }
	.thead-info th {
        background-color: $info;
        color: $white;
    }
	.thead-warning th {
        background-color: $warning;
        color: $white;
    }
	.thead-danger th {
        background-color: $danger;
        color: $white;
    }
	.thead-success th {
        background-color: $success;
        color: $white;
    }

    //table border
    &.primary-table-bordered {
        border: 1px solid $border;
		@at-root [data-theme-version="dark"] & {
            border-color: $d-border;
        }
    }

    //table hover
    &.primary-table-bg-hover {
        thead th {
            background-color: darken($color: $primary, $amount: 5%);
            color: $white;
            border-bottom: none;
        }

        tbody tr {
            background-color: $primary;
            color: $white;
            transition: all .1s ease;

            &:hover {
                background-color: lighten($color: $primary, $amount: 5%);
            }

            &:not(:last-child) {
                td, th {
                    border-bottom: 1px solid darken($color: $primary, $amount: 5%);
                }
            }
        }
    }

    //responsive table width
    &-responsive {
        &-tiny {
            // @include media-breakpoint-down(xs) {
                min-width: 18.75rem;
            // }
        }

        &-sm {
            // @include media-breakpoint-down(sm) {
                min-width: 30rem !important;
            // }
        }

        &-md {
            // @include media-breakpoint-down(sm) {
                min-width: 36rem !important;
            // }
        }

        &-lg {
            min-width: 60.9375rem !important;
        }

        &-xl {
            min-width: 70.9375rem !important;
        }
    }
}
.table-responsive{
	border-radius:$radius;
}
.table-primary, 
.table-primary > th, 
.table-primary > td {
	background-color: $primary-light;
	color: $primary;
	@at-root [data-theme-version="dark"] & {
        background-color: $primary-opacity;
    }
}
.table-success, 
.table-success > th, 
.table-success > td {
	background-color: $success-light;
	color: $success;
	@at-root [data-theme-version="dark"] & {
        background-color: $success-opacity;
    }
}
.table-info, 
.table-info > th, 
.table-info > td {
	background-color: $info-light;
	color: $info;
	@at-root [data-theme-version="dark"] & {
        background-color: $info-opacity;
    }
}
.table-warning, 
.table-warning > th, 
.table-warning > td {
	background-color: $warning-light;
	color: $warning;
	@at-root [data-theme-version="dark"] & {
        background-color: $warning-opacity;
    }
}
.table-danger, 
.table-danger > th, 
.table-danger > td {
	background-color: $danger-light;
	color: $danger;
	@at-root [data-theme-version="dark"] & {
        background-color: $danger-opacity;
    }
}
.table-active, 
.table-active > th, 
.table-active > td {
    background-color: $l-bg;
	@at-root [data-theme-version="dark"] & {
        background-color: $d-bg;
    }
}
.table > :not(:first-child) {
    border-top: 2px solid rgba(255,255,255,0.2);
}
























