@media (min-width: 767px) {

    [data-sidebar-style="icon-hover"][data-layout="horizontal"] {
		.metismenu {
			padding: 0 30px;
		}
		.header .header-content {
		    padding-left: 1.875rem;	
		}
	}
	[data-layout="vertical"][data-container="boxed"]{
		 .nav-header {
            z-index:9;
			left:auto;
		}
	}
	 [data-sidebar-style="icon-hover"][data-layout="vertical"][data-container="boxed"]{
			 .header {
				.header-content {
					padding-left: 1.375rem;

					@at-root [direction="rtl"]#{&} {
						padding-right: 1.375rem;
						padding-left: 0;
					}
				}
        }
	 }
    [data-sidebar-style="icon-hover"][data-layout="vertical"] {
       
        .nav-header {
            width: 5.9rem;
			

            .brand-logo {
                padding-left: 20px;
				@at-root [direction="rtl"]#{&} {
					padding-left:1.25rem;
					padding-right:1.25rem;
				}
                .logo-abbr {
                    display: block;
                }

                .brand-title {
                    display: none;
                }
            }

            .nav-control {
                display: none;
            }
			
        }
       
        .header {
            padding-left: 5.063rem;

            @at-root [direction="rtl"]#{&} {
                padding-right: 4.38rem;
                padding-left: 0.9375rem;
            }

            .header-content {
                padding-left: 2.5rem;

                @at-root [direction="rtl"]#{&} {
                    padding-right: 1.375rem;
                    padding-left: 0;
                }
            }
        }
        .iconhover-toggle{
			.nav-header {
				width:18rem;
				.brand-title {
					display: block;
				}
			}
            .deznav{
                left:0;
				.plus-box{
					opacity:1;
				}
				.copyright{
					opacity:1;
				}
            }
			
        } 
        .deznav {
            overflow: visible;
            position: absolute;
            left: -12.125rem;
            // z-index: 1;
			
			@include custommq($min: 1200px, $max: 1350px) {
                left: -10rem;
            }

            @at-root [direction="rtl"]#{&} {
                left: auto;
                right: -12.125rem;
            }

            .nav-label {
                display: none;
            }

            .metismenu {
                &>li {
                    &>a {
                        display: flex;
                        justify-content: space-between;
						padding: 0.938rem 1.875rem;
    
                        &>svg,
                        &>i {
                            order: 1;
							margin-right:0;
							margin-top:0;
                        }
                    }
                }

                ul {
					border-left: 0;
					padding-left:0;
					padding-right:28px;
					@at-root [direction="rtl"]#{&} {
						padding-right:0;
						padding-left:28px;
					}
					@at-root [data-theme-version="dark"]#{&} {
						border-color:$d-border;
					}
					&:after{
						left:auto;
						right:28px;
						@at-root [direction="rtl"]#{&} {
							left:28px;
							right:auto;
						}
					}
                    a {
                        position: relative;

                        &::before {
							left: auto;
							right: -5px;
						
                            @at-root [direction="rtl"]#{&} {
                                right: auto;
                                left:-5px;
                            }
                        }
                    }
                }
				.mm-collapse{
					li{
						a{
							padding-left: 1.75rem;
							display:none;
						}
					}
				}

                .has-arrow {
                    &::after {
                        right: 6rem;

                        @at-root [direction="rtl"]#{&} {
                            right: auto;
                            left: 5rem;
                        }
                    }
                }
            }
			.plus-box{
				opacity:0;
			}
			.copyright{
				opacity:0;
			}
        }

        .iconhover-toggle {

            .nav-header {
                width: 18rem;
    
                .brand-logo {
                    padding-left: 1.6rem;
    
    
                    .brand-title {
                        display: block;
                    }
                }
            }
    
            .header {
                padding-left: 4.38rem;

                @at-root [direction="rtl"]#{&} {
                    padding-right: 4.38rem;
                    padding-left: 0.9375rem;
                }
            }

            .deznav {
                left: 0;

                @at-root [direction="rtl"]#{&} {
                    left: auto;
                    right: 0;
                }
				.mm-collapse{
					li{
						a{
							
							display:block;
						}
					}
				}
            }
			
        }

        .content-body {
            margin-left: 5rem;

            @at-root [direction="rtl"]#{&} {
                margin-left: 0;
                margin-right: 5rem;
            }
        }

        .footer {
            padding-left: 4.375rem;

            @at-root [direction="rtl"]#{&} {
                margin-left: 0;
                margin-right: 4.375rem;
            }
        }
    }
 [data-container="boxed"][data-sidebar-style="icon-hover"][data-layout="vertical"]{
		.menu-toggle .deznav{
			position:absolute;
		}
	 }
	 [data-container="boxed"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"][data-layout="vertical"]{ 
		 
		 .deznav{
			left: auto;
			width:5.9rem;
			position:fixed;
			.metismenu{
				li{
					a{
						span{
							opacity:0;
							width:0;
						}
					}
				}
			}
		} 	
		 .iconhover-toggle{
			 .deznav{
				z-index: 99999;
				height: calc(100% - 90px);
				left: calc(calc(100% - 1199px) / 2);
				box-shadow: 20px 20px 20px 0 rgba(0,0,0,0.1);
				width: 18rem;
				.metismenu{
					li{
						a{
							span{
								opacity:1;
								width:unset;
							}
						}
					}
				}
			}
		}
		
		
	 }
 [data-container="wide-boxed"][data-sidebar-style="icon-hover"][data-layout="vertical"]{
	.deznav{
		position:absolute;
	}
	.menu-toggle .deznav{
		position:absolute;
	}
	 &[data-sidebar-position="fixed"]{
		.iconhover-toggle{
			 .deznav{
				z-index: 99999;
				height: calc(100% - 90px);
				left: calc(calc(100% - 1480px) / 2);
				box-shadow: 20px 20px 20px 0 rgba(0,0,0,0.1);
				width: 18rem;
				.metismenu{
					li{
						a{
							span{
								opacity:1;
								width:unset;
							}
							
						}
					}
				}
			}
		}
		.deznav{
			position:fixed;
			left: auto;
			width:5.9rem;
			.metismenu{
				li{
					a{
						span{
							opacity:0;
							width:0;
						}
					}
				}
			}
		} 	
		
	 }
 }
	

}