//demo styles
table#example{
    padding: 0rem 0 2rem 0;
}
table.dataTable{
	color:#3e4954;
	@at-root [data-theme-version="dark"] & {
       color: rgba(255,255,255,0.8);
    }
}
#example2_wrapper {
    .dataTables_scrollBody {
        max-height: 33.25rem !important;
    }
}

#employees, #custommers {
    padding: .5rem 0 1rem 0;
}
//////////
.dataTables_wrapper .dataTables_paginate{
    padding:10px 0;
	@include respond('tab-land') {
		padding:5px 0;
		display:inline-flex;
	}
}
table.dataTable thead th, 
table.dataTable thead td {
    padding: 25px 25px;
    border-bottom: 0px solid $border;
    @include respond('tab-land') {
		padding:8px 15px;
	}
}

table.dataTable tfoot th,
table.dataTable tfoot td{
    border-top: 0;
}
table.dataTable.shadow-hover tbody tr{
	background:transparent;
	&:hover{
		box-shadow:0px 0px 20px 1px var(--rgba-primary-3);
		background:transparent;
	}
}
table.dataTable.patient-list tbody tr{
	&:hover{
		box-shadow:0px 0px 20px 1px var(--rgba-primary-3);
		background:$white;
	}
}
table.dataTable tbody tr{
	background:#fff;
}
table.dataTable thead th{
    color: $black;
	font-size:18px;
	text-transform:capitalize;
	white-space:nowrap;
    font-weight: 400;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
	@include respond('tab-land') {
		font-size:16px;
	}
}

table.dataTable tbody td{
    padding: 25px 25px;
	background:transparent;
	@include respond('tab-land') {
		padding:8px 15px;
	}
	
}
[data-theme-version="dark"]{
	table.dataTable.display tbody tr.odd>.sorting_1,
	table.dataTable.display tbody tr.even>.sorting_1, 
	table.dataTable.order-column.stripe tbody tr.even>.sorting_1{
		background:transparent;
	}
	//table.dataTable.display tbody tr.odd>.sorting_1, 
	table.dataTable.order-column.stripe tbody tr.odd>.sorting_1,
	table.dataTable.stripe tbody tr.odd, 
	table.dataTable.display tbody tr.odd{
		background:$d-bg;
	}
	table.dataTable.hover tbody tr:hover, 
	table.dataTable.display tbody tr:hover{
		background:rgba(0, 0, 0, 0.2);
	}
}

table.dataTable tr.selected{
    color:var(--primary);
}

table.dataTable tfoot th{
    color: $dark;
    font-weight: 600;
    @at-root [data-theme-version="dark"] & {
        color: $white;
    }
}
.dataTables_wrapper {
    input[type="search"], input[type="text"], select {
        border: 1px solid #e2e2e2;
        padding: .3rem 0.5rem;
        color: rgba(113, 93, 93, 1);
        border-radius: 5px;
        @at-root [data-theme-version="dark"] & {
            background: $d-bg;
            border-color: $d-border;
            color: $white;
        }
    }
	.dataTables_length{
		margin-bottom: 15px;
		.bootstrap-select{
			width: 80px!important;
			margin: 0 5px;
			.btn{
				padding: 10px 10px!important;
			}
		}
	}
}
table.dataTable.no-footer{
    border-bottom: 0;
}


.rounded-lg{
	min-width:30px;
}
.dataTables_scroll{
    padding: 1rem 0;

    &Foot{
        padding-top: 1rem;
    }
}
.dataTables_wrapper .dataTables_length, 
.dataTables_wrapper .dataTables_filter {
    @include media-breakpoint-down(md) {
        text-align: left;
    }
}
.dataTablesCard{
	background-color: #fff;
	box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
	border-radius: 20px;
	
	tbody tr:hover{
		background:#f3f8f6;
	}
}

.dataTables_wrapper .dataTables_info {
    padding:10px 0;
	color:$white;
	@include respond('tab-land') {
		padding:8px 0;
	}
}
.no-data-img{
	background-image:none !important;
}
table.dataTable.row-border tbody th, 
table.dataTable.row-border tbody td, 
table.dataTable.display tbody th, 
table.dataTable.display tbody td {
    border-color: $border;
    @at-root [data-theme-version="dark"] & {
        border-color: $d-border;
    }
}
table.dataTable thead .sorting{
	background-position: center right 15px;
}

.dataTables_wrapper .dataTables_paginate{

	span{
		background:transparent;
		border-radius:$radius;
		display: inline-flex;
		margin: 0 10px;
			
		a{
			color:var(--primary);
			background: transparent !important;
		}
	}
	.paginate_button{
		border: 0 !important;
		padding: 10px 10px;
		height:43px;
		width:43px;
		background:transparent;
		border-radius: $radius;
		color:var(--primary) !important;
		font-size:16px;
		display:inline-block;
		@include respond('phone') {
			padding: 12px 15px;
			font-size:14px;
		}
		&:hover,
		&.current{
			color:$white !important;
			background:var(--primary) !important;
		}
		
		&.previous,
		&.next{
			background:var(--primary);
			color:$white !important;
			width:100px;
			&:hover{
				color:$white !important;
				background:transparent;
			}
		}
	}
}
table.dataTable tbody tr, table.dataTable tbody td {
    background: $white !important;
	border: transparent;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc{
	background-image:none;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_asc:after {
    content: "";
    font-family: "Font Awesome 5 Free";
    font-weight: 700;
    margin-left: 5px;
    font-size: calc(100% - 2px);
    opacity: 0.5;
}
table.dataTable thead .sorting:first-child:after {
    content: "\f0dc";
	display:none;
}
table.dataTable thead .sorting:after {
    content: "\f0dc";
}
table.dataTable thead .sorting_asc:after {
    content: "\f0de";
    opacity: 1;
}
table.dataTable thead .sorting_desc:after {
    content: "\f0dd";
    opacity: 1;
}


