

.page-titles {
    background: #f0f5f2;
    margin-bottom: 30px;
    border-radius: 5px;
    margin-left: -40px;
    margin-right: -40px;
    padding: 20px 40px;
    margin-top: -40px;
    border-radius: 0;
    @at-root [data-theme-version="dark"] & {
        background: $dark-card;
    }
	@include respond ('laptop'){
		margin-top:-30px;
	}
    @include respond('phone-land') {
        margin-bottom: 15px;
		margin-top:-20px;
    }
	@include respond('phone') {
		margin-top:-15px;
    }

    .justify-content-sm-end{
        align-items: center;
    }

    h4 {
        margin-bottom: 0;
        margin-top: 0;
        color: var(--primary);
        font-size: 1.25rem;
        span {
            font-size: 0.875rem;
            font-weight: 400;
        }
    }
    .breadcrumb {
        margin-bottom: 0;
        padding: 0;
        background: transparent;
        // font-size: 0.875rem;
        li {
            margin-top: 0;
            margin-bottom: 0;

            a{
               color: $l-ctl; 

            }

            &.active{
				a{
					color: #828690;
				}
            }
			&:before{
				color:var(--primary);
			}
        }

        &-datepicker{
            font-size: 0.75rem;
            color: $muted;

            &__icon{
                font-size: 0.875rem;
            }
        }
    }

    .breadcrumb-widget{
        .border-dark{
            border-color: $gray-300!important;
        }
        h4{
            color: $strong;
            font-weight: 600;
        }
        @include respond('phone') {
            text-align: left!important;
            margin-bottom: 0.9375rem;
        }
    }
    
}

