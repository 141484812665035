[data-theme-version="dark"] {
    .nav-header, 
    .nav-control {
        background-color:$dark-card;
    }

    .nav-control {
        color: $white;
    }

    .brand-logo {
        color: $white;
		box-shadow: none;

        &:hover {
            color: $white;
        }
    }
}