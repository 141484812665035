.order-request{

	 tbody{
		
		tr{
			display: block;
			padding: 0 20px;
			position: relative;
			box-shadow: 0 1px 0 0 #f8f8f8;
			@include transitionMedium;
			&.active,
			&:hover{
				box-shadow: 0 0 20px -3px rgba(0, 0, 0, 0.15);
				z-index: 1;
			}
			th,
			td{
				border: 0;
				padding:18px 8px;
				&:nth-child(1){
					width:35%;
					@include respond('laptop') {
						width:40%;
					}
				}
				&:nth-child(2){
					width:17%;
					@include respond('laptop') {
						width:20%;
					}
				}
				&:nth-child(4){
					width:18%;
				}
			}
			.media{
				@include respond('phone-land') {
					width:250px;
				}
			}
		}
	}
	@include respond('phone') {
		width:500px;
	}	
}
.select2-container--default .select2-results__option[aria-selected=true] {
  @at-root [data-theme-version="dark"] & {
        background-color: $dark-card;
    }
   
}
.wspace-no{
	white-space:nowrap;
}
.star-review{
	i{
		font-size:16px;
		&.text-gray{
			color:#d9d9d9;
		}
	}
	span{
		color:$black;
	}
}
.chart-deta{
	.col{
		display: flex;
		padding: 0;
		margin-right: 15px;
		margin-bottom: 30px;
		&:last-child{
			margin-bottom:0;
		}
		@include respond('phone') {
			margin-bottom: 5px;
		}	
		p{
			margin-bottom:5px;
			line-height:1.5;
			font-weight: 500;
			@include respond('phone') {
				font-size: 14px;
				display: inline-block;
			}	
		}
		h3{
			font-size:34px;
			margin-bottom:0;
			color:$black;
			@include respond('phone') {
				font-size: 14px;
				display: inline-block;
			}	
		}
		[class*="bg-"]{
			height: 30px;
			width: 30px;
			border-radius: 50px;
			margin-right: 25px;
			@include respond('phone') {
				height: 16px;
				width: 16px;
				margin-right: 10px;
				margin-top: 5px;
			}	
		}
		
	}
}
.trending-menus{
	.timeline {
		.timeline-panel{ 
			padding: 25px 30px 30px 30px;
			@include transitionMedium;
			border-bottom: 1px solid $border-color;
			margin-bottom: 0;
			@include respond('phone') {
				display:block;
				padding: 15px 15px 30px 15px;
			}	
			&.active,
			&:hover{
				box-shadow: 0 5px 35px 0 rgba(0,0,0,0.05);
			}
			.media{
				width: 60px;
				height: 60px;
				position: relative;
				overflow: unset;
				border-radius:$rounded;
				@include respond('phone') {
					width: 80px;
					height: 80px;
					float:left;
					margin-right:15px !important;
				}	
				img{
					border-radius:$rounded;
					width:100%;
				}
				.number{
					width:42px;
					height:42px;
					border-radius:40px;
					position:absolute;
					bottom:-25px;
					left:50%;
					border:3px solid $white;
					line-height:38px;
					transform: translateX(-50%);
					text-align:center;
					background:$warning;
					color:$black;
					font-size: 16px;
					font-weight: 600;
				}
			}
		}
		.media-body{
			@include respond('phone') {
				margin-bottom:10px;
			}	
		}
		.social-media a{
			padding: 0;
			font-size: 18px !important;
			width: 40px;
			height: 40px;
			line-height: 40px;
		}
		li:last-child .timeline-panel{
			padding-bottom:20px;
		}
	}
	
}
// Form Head
.form-head{
	.settings-icon{
		height:56px;
		width:56px;
		line-height:56px;
		text-align:center;
		color:$white;
		border-radius:4rem;
		background:#FF6D4C;
		font-size:25px;
		@include respond('laptop') {
			height:41px;
			width:41px;
			line-height:41px;
			font-size:22px;
		}
	}
	.search-area{
		max-width:250px;
		border-radius: 3rem;
		background:$white;
		@include respond('laptop') {
			max-width:180px;
		}
		@include respond('phone') {
			max-width:250px;
		}
		.form-control{
			border: 0;
			background:transparent;
			padding: 10px 30px;
			font-size: 16px;
			@include respond('laptop') {
				height: 41px;
				padding: 10px 15px;
				font-size: 13px;
			}
		}
		.input-group-append .input-group-text{
			background:transparent;
			padding: 0 30px;
			@include respond('laptop') {
				padding: 0 15px;
			}
			i {
				font-size: 24px;
				color:$body-color;
				@include respond('laptop') {
					font-size: 18px;
				}
			}
		}
	}
	.dropdown {
		.btn{
			
			i{
				transform: scale(1.3);
				-moz-transform: scale(1.3);
				-webkit-transform: scale(1.3);
				-ms-transform: scale(1.3);
				-o-transform: scale(1.3);
				display: inline-block;
			}
		}
	}
	.btn i{
		line-height: 1;
		transform: scale(1.3);
		display: inline-block;
		margin-right: 5px;
	}
	.btn-outline-primary{
		border-color:#eee;
		color:$black;
		&:hover{
			border-color:var(--primary);
			color:$white;
			svg{
				g{
					path{
						fill:$white;
					}
				}
			}
			&:after{
				color:$white;
			}
		}
		&:after{
			color:$primary;
			font-size:18px;
		}
	}
	& > *{
		@include respond('laptop') {
			margin-right:5px !important;
			margin-bottom:10px;	
			display: inline-block;	
			vertical-align: baseline;
		}
		@include respond('tab-port') {
			margin-left:0 !important;
		}
	}
	@include respond('phone-land') {
		display:block !important;
	}
}

.rounded{
	border-radius:$radius !important;
}
.btn-link{
    font-weight: 500;
    font-size: 16px;
}
.plus-box{
	background:var(--primary-hover);
	color:$white;
	font-size:16px;
	padding: 35px 20px;
	position:relative;
	display: flex;
    overflow: hidden;
    margin: 0 30px;
    border-radius: 1rem;
	align-items:center;
	margin-bottom: 30px;
    margin-top: 30px;
	i{
		height:40px;
		width:40px;
		line-height:40px;
		border-radius:8px;
		margin-left: 10px;
		text-align:center;
		background:rgba($white,0.4);
	}
	p{
		margin-bottom:0;
		line-height:1.5;
	}

	@include respond('tab-land') {
		margin: 25px 30px;
	}
	@include respond('phone-land') {
		margin: 0 20px;
		margin-bottom: 20px;
		margin-top: 20px;
	}
	&:after{
		position:absolute;
		content:"";
		right: 0;
		bottom: 0;
		height: 30px;
		width: 35px;
		background-image:url('../images/dots2.png');
		background-repeat:no-repeat;
		opacity: 1;
	}
	&:before{
		position:absolute;
		content:"";
		left: 20px;
		top: 0px;
		height: 30px;
		width: 30px;
		background-image:url('../images/dots.png');
		background-repeat:no-repeat;
		opacity: 1;
	}
}
.deznav .copyright{
	font-size:12px;
	padding: 0 30px;
	margin-bottom: 40px;
	color:$side-text;
	
	p{
		margin-bottom:10px;
	}
	@include respond('tab-land') {
		padding: 0 30px;
	}
	@include respond('phone-land') {
		padding:0 20px;
	}
}
.recentOrderTable {
    table {
        margin-bottom: 0;
        overflow: hidden;
        thead {
            th {
                font-size: 12px;
            }
        }
        tbody {
            td {
                color: $dark;
                font-weight: 500;
                line-height: 40px;
                @at-root [data-theme-version="dark"] & {
                    color: $d-ctl;
                }
                img {
                    border-radius: 50px;
                }
                .custom-dropdown {
                    i {
                        padding: 0 10px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
    background-color: transparent!important;
    opacity: 0.9;
}
#user-activity {
    
    .nav-tabs {
        border-bottom: 0px;
        .nav-link {
            // color: #fff;
            border-radius:0;
            margin-left: -1px;
            padding: 5px 15px;
			border:1px solid $light;
            &.active {
                background: var(--primary);
                color: #fff;
				border-color: var(--primary);
            }
        }
		li{
			&:first-child a{
				border-radius:$radius 0 0 $radius;
			}
			&:last-child a{
				border-radius: 0 $radius $radius 0;
			}
		}
    }
}
#activeUser {
    height: 215px!important;
}
#activeUser {
    height: 180px!important;
}
span#counter {
    font-size: 30px;
    font-weight: 700;
    color: var(--primary);
}
.social-graph-wrapper {
    text-align: center;
    padding: 20px;
    position: relative;
    color: $white;
    border-top-left-radius: $radius;
    border-top-right-radius: $radius;
    // @include respond('big-desktop') {
    //     padding: 15px 10px 20px;
    // }
    &.widget-facebook {
        background-color: $facebook;
    }
    &.widget-twitter {
        background-color: $twitter;
    }
    &.widget-linkedin {
        background-color: $linkedin;
    }
    &.widget-googleplus {
        background-color: $google-plus;
    }
    .s-icon {
        font-size: 24px;
        position: relative;
        // top: 0.625rem;
        padding: 0 10px;
    }
}
.recent-stats i{
    font-size: 11px;
    position: relative;
    top: -1px;
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
	#lineChart_3Kk{
		height: 140px !important;
	}
}

//nav-tabs
.card-tabs .nav-tabs {
    background: transparent;
    padding: 5px;
	border-width:0px;
    flex-wrap: unset;
	
	.nav-link{
		border-radius: 5px;
		padding: 0px 15px 15px;
		font-weight: 500;
		color:var(--rgba-primary-6);
		font-size: 14px;
		@include transitionMedium;
		border: 0;
		position:relative;
		&:after{
			position:absolute;
			width:100%;
			height:0;
			background:var(--primary);
			bottom:0;
			@include transitionMedium;
			border-radius:5px 5px 0 0;
			left:0;
			content:"";
		}
		&.active{
			background:transparent;
			color: var(--primary);
			&:after{
				height:6px;
			}	
		}
	}
	.nav-item{
	    display: inline-block;
	}
	@include respond('phone') {
		display:block;
	}
}

.iconbox{
    position: relative;
    padding: 15px 30px 15px 70px;
	border-radius:$radius;
	display:inline-block;
	background:#838d97;
	text-align:left;
	i{
		width:70px;
		height:70px;
		line-height:70px;
		text-align:center;
		font-size:32px;
		color:$white;
		position:absolute;
		left:0;
		top: 50%;
		transform: translateY(-50%);
	}
	p{
		margin: 0;
		color: $white;
		font-size: 18px;
		line-height: 1.3;
		font-weight: 500;
	}
	small{
		margin-bottom: 5px;
		font-size: 16px;
		display: block;
		color:$white;
	}
}
.widget-timeline-icon{
	padding:50px;
	@include respond('laptop') {
		padding:30px;
	}
	@include respond('phone') {
		overflow:scroll;
		padding:15px;
	}
	.timeline{
		display:flex;
		@include respond('phone') {
			display:block;
			margin-left: 10px;
		}
	}
	li{
		position: relative;
		border-top: 2px solid $warning;
		@include respond('phone') {
			border-top: 0;
			border-left: 3px solid $warning;
		}
		a{
			padding: 15px 50px 0px 0px;
			display:block;
			@include respond('laptop') {
				padding: 15px 25px 0px 0px;
			}
			@include respond('phone') {
				padding: 0px 0px 30px 30px;
			}
		}
		
		.icon{
			position: absolute;
			width: 10px;
			height: 10px;
			font-size: 24px;
			color: #fff;
			text-align: center;
			line-height: 56px;
			border-radius: 56px;
			left:-2px;
			top: -6px;
			@include respond('phone') {
				left: -7px;
				top: -4px;
			}
		}
		.timeline-panel{
			
		}
		&:last-child{
			border-top: 3px solid transparent;
			@include respond('phone') {
				border-left: 3px solid #eee;
			}
			
		}
	}
}


.order-list{
	.media{
		@include respond('phone-land') {
			width:290px;
		}
	}
}
.full-map-area {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
	a{
		position: absolute;
		bottom: 30px;
		right: 30px;
		background:#3f4953;
		padding:12px;
		border-radius:$radius;
	}
	.map-text{
		position:absolute;
		top:30px;
		right:30px;
	}
}
.favorites-items{
	
	img{
		width:110px;
	}
}

.ticket-bx{
	color:var(--primary);
	background: rgb(19,180,151); 
	background: -moz-linear-gradient(-45deg, var(--primary) 0%, var(--primary-hover) 100%);
	background: -webkit-linear-gradient(-45deg, var(--primary) 0%, var(--primary-hover) 100%); 
	background: linear-gradient(135deg, var(--primary) 0%, var(--primary-hover) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#13b497', endColorstr='#05737a',GradientType=1 );
	.progress{
		background:rgba($white,0.2);
	}
	.progress-bar-striped {
		background-image: linear-gradient(45deg, rgba(255 ,255, 255, 1),
		transparent 25%,
		transparent 50%,
		rgba(255, 255, 255,1),
		rgba(255, 255, 255,1),
		transparent 75%,
		transparent);
		background-size: 2rem 2rem;
	}
	p{
		color:$white;
	}
}

//event bax

.event-bx{
	.image{
		position:relative;
		margin-right:30px;
		img{
			width:128px;
			border-radius:$radius;
			@include respond ('phone'){
				width:90px;
			}
		}
		.image-icon{
			position:absolute;
			bottom:0;
			right:0;
			width:43px;
			height:31px;
			border-radius:$radius 0 $radius 0;
			line-height:31px;
			text-align:center;
			font-size: 18px;
			background:var(--primary);
			color:$white;
			@include respond ('phone'){
				width:30px;
			}
		}
		@include respond ('phone-land'){
			float:left;
		}
		@include respond ('phone'){
			margin-right:15px;
		}
	}
	.media-footer{
		display:flex;
		width: 44%;
		justify-content: space-evenly;
		margin-top: auto;
		margin-bottom: auto;
		@include respond ('phone-land'){
			width: 100%;
			justify-content: left;
			&>div{
				margin-right:30px;
			}
		}
		@include respond ('phone'){
			&>div{
				margin-right:20px;
			}
		}
	}
	.card-body{
		height:500px;
	}
}
.ticket-icon{
	height:52px;
	width:52px;
	min-width:52px;
	display:block;
	margin-left:auto;
	margin-right:auto;
	border-radius:100%;
	line-height:52px;
	text-align:center;
	background-color: var(--primary);
	svg{
		path{
			fill:#ffffff;
		}
	}
}
.index-chart-point {
    display: flex;
	align-items: center;
		
	.check-point-area {
		width: 150px;
		padding-top: 8px;
		height: 100px;
		margin-top: -10px;
		margin-left: -10px;
	}
}
.index-chart-point-list{
	li{
		width:94px;
		float:left;
		margin-right: 3px;
		color:#2f363d;
		font-size: 14px;
		i{
			margin-right:8px;
			font-size:10px;
		}	
	}
}
.mt-widget {
    margin-top: -40px;
}
.min-w22{
	min-width:22px;
}
.min-w50{
	min-width:50px;
}
.header-right{
	.search-area{
		.form-control{
			background: #f0f5f2;
			border-top-right-radius: $radius!important;
			border-bottom-right-radius: $radius!important;
			width: 200px;
			z-index: 0!important;
			padding-right:2.5rem;
		}
		.input-group-text{
			background: transparent!important;
			position: absolute;
			right: 0px;
			top: 50%;
			transform: translatey(-50%);
		}
		@media screen and (max-width:1300px){
			display: none!important;
		}
	}
}
.selling{
	background-color: #f3f8f6;
	span{
		color: #06777c;
	}
}

.dz-theme-mode #icon-light {
  display: none; 
  }

.dz-theme-mode.active #icon-dark {
  display: none; }

.dz-theme-mode.active #icon-light {
  display: inline-block; 
  line-height: 30px;
  @include respond('laptop'){
	line-height: 1;
  }
}
.dz-theme-mode #icon-dark {
    display: inline-block;
	line-height: 30px;
	@include respond('laptop'){
		line-height: 1;
	}
}
@media only screen and (max-width: 575px) {
	.dz-theme-mode {
		display: none; 
	} 
}
.media{
	display: flex;
	align-items: flex-start;
}
.media-body{
	flex: 1;
}
.btn-primary{
	color: #fff;
}
.btn-block{
	display: block;
	width: 100%;
}
.event-btn{
	height:4.813rem;
	padding:1.5rem;
}
.default-select{
	&.style-1{
		select{
			width: 0!important;
		}
		width: auto!important;
		&.bootstrap-select .btn{
			background-color: transparent!important;
			border-color: transparent!important;
			padding: 0;
		}
		.filter-option-inner-inner{
			font-size: 14px;
			 @at-root [data-theme-version="dark"] & {
				color:$d-ctd;
			}
		}
	}
	&.style-2{
		select{
			width: 0!important;
		}
		width: auto!important;
		&.bootstrap-select .btn{
			background-color: transparent!important;
			border-color: transparent!important;
			padding: 0;
			&.dropdown-toggle{
				&:after{
					margin-left: 10px;
					margin-top: 3px;
				}
			}
		}
		.filter-option-inner-inner{
			font-size: 18px;
		}
	}
}
.form-group{
	margin-bottom: 1rem;
}
.new-arrival-content.text-center .price{
	float: unset;
}
label.col-form-label a{
	color: var(--primary);
}
.post-input .btn-social{
	font-size: 1.25rem;
    height: 3.4375rem;
    display: inline-block;
    padding: 0;
    text-align: center;
    border-radius: 0.5rem;
    color: #fff;
    width: 3.4375rem;
    line-height: 3.375rem;
	&.facebook{
		background-color: #3B5998;
	}
	&.google-plus{
		background-color: #DE4E43;
	}
	&.linkedin{
		background-color: #007BB6;
	}
	&.instagram{
		background-color: #8A5A4E;
	}
	&.twitter{
		background-color: #1EA1F3;
	}
	&.youtube{
		background-color: #CE201F;
	}
	&.whatsapp{
		background-color: #01C854;
	}
}
.event{
	@include respond('phone'){
		width: 300px;
	}
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback){
	border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}
.form-1{
	border-radius: 0!important;
}
.ck{
	height:200px;
}
.input-group .bootstrap-select>.dropdown-toggle:after{
	display:none;
	 @at-root [data-theme-version="dark"] & {
		color:$d-ctd;
	}
}
#lineChart{
	svg{
		g{
			path{
				fill:var(--primary);
			}
			
		}
	}
	tspan{
		fill:var(--primary);
	}

}
// ---chackbox-----
.round {
    margin-right: 1.5rem;
    position: relative;
    padding-left: 2.2rem;
    cursor: pointer;
    font-size: 0.875rem;
    user-select: none;
    input{
        position: absolute;
        top: 0;
        left: 0;
        height: 1.25rem;
        width: 6.25rem;
        background-color: $body-color;
        opacity: 0;
      }
    span{
        color: $side-text;
		 @at-root [data-theme-version="dark"] & {
			color:$d-ctd;
		}
    }
    .checkmark {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        height: 24px;
        width: 24px;
        background-color: $white;
        border-radius: 50%;
        border: 3px solid var(--primary);
        padding: 2px;
        margin: 0;
		@at-root [data-theme-version="dark"] & {
			background-color:$d-bg;
		}
      }
}
.round.weekly{
    .checkmark {
        border: 3px solid var(--secondary);
    }
}
  .round:hover input ~ .checkmark {
    background-color: transparent;
  }
  
  .round input:checked ~ .checkmark {
    background-color: $white;
    border: 2px solid var(--primary);
	@at-root [data-theme-version="dark"] & {
		background-color:$d-bg;		
	}
  }
  .round.weekly input:checked ~ .checkmark {
    background-color:$white;
    border: 2px solid var(--secondary);
	@at-root [data-theme-version="dark"] & {
		background-color:$d-bg;		
	}
  }
  
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  

  .round input:checked ~ .checkmark:after {
    display: block;
  }
  .round.weekly input:checked ~ .checkmark:after {
    display: block;
    background-color:var(--secondary) ;
  }
  .round .checkmark:after {
    width: 100%;
    height: 100%;
    background-color: var(--primary);
    border-radius: 50%;
    position: relative;
  }
  .round.disabled {
    opacity: 1;
}
 .custome-tooltip{
	.apexcharts-tooltip.apexcharts-theme-light{
		width: 6.563rem;
	}
	.apexcharts-xaxistooltip { 
		color: #ffffff;
		background: var(--primary);
		border: 1px solid var(--rgba-primary-1);
		border-radius: 0.625rem;
	   
	}
	.apexcharts-xaxistooltip-bottom:after {
		border-bottom-color: var(--primary);
	}
}
.custome-tooltip{
    .apexcharts-tooltip.apexcharts-theme-light{
          
            font-size: 1.125rem;
            background-color: var(--primary);
            border-radius: $radius;
            border: none;
            overflow: unset;
            &::before{
                content: '';
                display: none;
                background-color: var(--primary);
                width: 1.5rem;
                height: 1.5rem;
                position: absolute;
                top: 90%;
                left: 50%;
                transform: rotate(45deg) translateX(-50%);
                z-index: -1;
            }
        }
    .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
        display: none;
    }
    .apexcharts-tooltip-series-group.apexcharts-active {
        justify-content: center;
        font-size: 1.125rem;
        color: $white;
    }
    .apexcharts-tooltip-text {
        font-size:  0.875rem !important;
    }
    .apexcharts-tooltip-series-group.apexcharts-active .apexcharts-tooltip-marker {
        opacity: 1;
        display: none;
    }

}
// ------chack---
// ------report----

.dots-text{ 
	margin:auto 0.25rem;
	p {
		white-space: nowrap;
	}
}
.bootstrap-select .dropdown-menu li a span.text {
    font-size: 0.875rem;
}	
span.dots{
	width: 20px;
	height: 20px;
	border-radius:50%;
	margin-right:0.5rem;
}


.custom-react-select{
	& > div:nth-child(4){
		& > div{
			background-color: #fff;
			& > div{
				background-color: #fff;
				cursor: pointer;
				@include transitionMedium;
				&:hover{
					background-color: var(--rgba-primary-1);
					color: #000;
				}
			}
		}
	}
	& > div {
		&:nth-child(3){
			height: 3rem;
			background-color: #fff;
			border-radius: .5rem;
			border-color: #B3B3B3!important;
			//box-shadow: 0px 7px 15px 0px rgba(61, 70, 83, 0.1)!important;
			@include respond('laptop') {
				height: 2.5rem;
			}
		}
		&:nth-child(4){
			border-radius: 10px;
			min-width: 150px;
			//border: 2px solid var(--primary);
			overflow: hidden;
			box-shadow: 0px 10px 40px 0px rgba(21, 50, 93, 0.1);
		}
		& > div{
			&:first-child{
				div{
					font-size: 1rem;
					color: $body-color;
				}
			}
			&:nth-child(2){
				& > span{
					display: none;
				}
			}
		}
	}
	
}