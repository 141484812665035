[data-theme-version="dark"] {
    .footer {
        background-color: transparent;

        .copyright {
            background-color: transparent;

            p {
                color: #8b8b8b;
				a{
					color: #ede4e4;
				}
            }
        }
    }
}