@media (min-width: 992px) {
    [data-container="boxed"] {
        #main-wrapper {
            max-width: 1199px;
            margin: 0 auto;
			border-width: 0 1px;
			border-style: solid;
			border-color:$border-color;
		/* 	@at-root [data-theme-version="dark"] & {
				
				border-color: $border-color;
			} */
            @at-root [direction="rtl"]#{&} {
                text-align: right;
            }
			
        }
		li.nav-item.dropdown.header-profile {
			display: none;
		}
    }
	
}

    
    
@include custommq($min: 1350px) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] {
        .header {
            width: 1199px;
			padding-left: 17.8rem;
			.header-content {
				padding-left: 6.3125rem;
				padding-right: 1rem;
			}
        }
    }
}

@include custommq($min: 1200px, $max: 1349px) {
    [data-layout="vertical"][data-sidebar-style="overlay"][data-container="boxed"][data-header-position="fixed"] {
        .header {
            width: 1199px;
        }
    }
}


[data-header-position="fixed"][data-layout="horizontal"][data-container="boxed"] {
    .deznav {
        max-width: 1199px;
    }
} //ok

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"] {
    .header {
        width: 1199px;
    }
}

[data-layout="horizontal"][data-container="boxed"][data-header-position="fixed"][data-sidebar-style="mini"] {
    .header {
        width: 1199px;
    }
}
[data-sidebar-style="mini"][data-sidebar-position="fixed"][data-layout="vertical"][data-container="boxed"],
[data-sidebar-style="compact"][data-sidebar-position="fixed"][data-layout="vertical"][data-container="boxed"]{
	.deznav {
		position: fixed;
	}
}

[data-container="boxed"] {
    .metismenu.fixed {
        left: auto;
        max-width: 1199px;
    }

    .page-titles {
        margin-bottom: 3rem;
        padding: 15px 15px;
        margin-top: 10px;
    }

    .content-body .container-fluid {
        padding: 0.9375rem 0.9375rem 0 0.9375rem;
    }
}

[data-container="boxed"][data-layout="vertical"] {
    .page-titles {
        margin-left: 0;
        margin-right: 0;
    }
	
}

[data-layout="vertical"][data-container="boxed"][data-sidebar-position="fixed"][data-header-position="static"][data-sidebar-style="overlay"] {
    .nav-header {
        position: fixed;
    }

    .menu-toggle {
        .deznav {
            position: fixed;
        }
    }
}

[data-container="boxed"][data-sidebar-position="fixed"][data-layout="horizontal"] {
    .deznav.fixed {
        left: auto;
        max-width: 1197px;
    }
}
[data-container="boxed"][data-layout="vertical"][data-sidebar-style="icon-hover"][data-header-position="fixed"] {
	.header{
		max-width: 1197px;
	}
}
[data-sidebar-position="fixed"][data-layout="vertical"][data-container="boxed"]{
	.deznav{
		position:absolute;
	}
	
}
[data-container="boxed"][data-layout="vertical"][data-sidebar-style="icon-hover"]{
	.content-body {
		margin-left: 5.8rem;
	}
	
}
[data-container="boxed"][data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="icon-hover"],
[data-container="boxed"][data-header-position="fixed"][data-layout="horizontal"][data-sidebar-position="fixed"][data-sidebar-style="full"] 
{
	.content-body{
		padding-top: 16.4rem;
	}
}
